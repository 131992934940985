var mybutton = document.getElementById("scrollTop");
if (mybutton) {
    window.onscroll = function () { scrollFunction() };
    mybutton.addEventListener('click', TopscrollTo);
}

function scrollFunction() {
    var body = document.body,
        html = document.documentElement;

    var height = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);

    if (document.body.scrollTop > (height - 1000) || document.documentElement.scrollTop > (height - 1000)) {
        if (window.innerWidth > 767) {
            mybutton.style.display = "block";

        }
    } else {
        mybutton.style.display = "none";
    }
}

function TopscrollTo() {
    console.log('scroll!');
    jQuery("html, body").animate({ scrollTop: 0 }, "slow");
}

// scroll to bottom
var smoothScroll = function (elementId) {
    var MIN_PIXELS_PER_STEP = 16;
    var MAX_SCROLL_STEPS = 30;
    var target = document.getElementById(elementId);
    var scrollContainer = target;
    do {
        scrollContainer = scrollContainer.parentNode;
        if (!scrollContainer) return;
        scrollContainer.scrollTop += 1;
    } while (scrollContainer.scrollTop == 0);

    var targetY = 0;
    do {
        if (target == scrollContainer) break;
        targetY += target.offsetTop;
    } while (target = target.offsetParent);

    var pixelsPerStep = Math.max(MIN_PIXELS_PER_STEP,
        (targetY - scrollContainer.scrollTop) / MAX_SCROLL_STEPS);

    var iterations = 0;
    var stepFunc = function () {
        if (iterations > MAX_SCROLL_STEPS) {
            return;
        }
        scrollContainer.scrollTop =
            Math.min(targetY, pixelsPerStep + scrollContainer.scrollTop);

        if (scrollContainer.scrollTop >= targetY) {
            return;
        }

        window.requestAnimationFrame(stepFunc);
    };

    window.requestAnimationFrame(stepFunc);
}
let buttonLink = document.querySelector('.home-slider__button-link');
if (buttonLink) {
    buttonLink.addEventListener("click", function (e) {
        e.preventDefault();
        smoothScroll('video-img-block-section');
    });
}


/** Dropdown language */
let languageActive = document.querySelector('.header-content__language-nav-active');
if (languageActive) {
    languageActive.addEventListener("click", function (e) {
        e.preventDefault();
        var x = document.querySelector(".header-content__language-nav ul");
        x.classList.toggle("opened");
    });
}

document.addEventListener("click", (evt) => {
    const flyoutElement = document.querySelector('.header-content__language-nav');
    let targetElement = evt.target; // clicked element

    do {
        if (targetElement == flyoutElement) {
            // Do nothing, just return.
            return;
        }
        // Go up the DOM.
        targetElement = targetElement.parentNode;
    } while (targetElement);

    // Do something useful here.
    if (document.querySelector(".header-content__language-nav ul").classList.contains("opened"))
        document.querySelector(".header-content__language-nav ul").classList.remove("opened");
});


